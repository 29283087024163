<template>
    <div class="home">
        <FirstSection
            v-if="$store.state.settings.start_on_login_page === '0'"
        />
        <SecondSection
            v-if="$store.state.settings.start_on_login_page === '0'"
        />
        <!-- <ThirdSection
            v-if="$store.state.settings.start_on_login_page === '0'"
        /> -->
        <LoginSection
            v-if="$store.state.settings.start_on_login_page === '1'"
        />
    </div>
</template>

<script>
import FirstSection from '../components/landingPage/FirstSection.vue';
import SecondSection from '../components/landingPage/SecondSection.vue';
// import ThirdSection from '../components/landingPage/ThirdSection.vue';
import LoginSection from '../components/landingPage/LoginSection.vue';
// @ is an alias to /src

export default {
    components: {
        FirstSection,
        SecondSection,
        // ThirdSection,
        LoginSection,
    },
    name: 'LandingPage',

    computed: {},

    mounted() {},
};
</script>
