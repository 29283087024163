<template>
    <v-container class="" max-width="1080" fluid>
        <v-row>
            <v-col
                key="left"
                cols="auto"
                sm="12"
                md="12"
                lg="6"
                justify="center"
                text-align="center"
                class="padding"
            >
                <h1>
                    <span class="primary_color">SprogTrappeHusets </span>
                    videokurser
                </h1>

                <div class="subheading-wrapper">
                    <p class="text">
                        Videokurserne er en del af SprogTrappeHuset. Her kan du
                        udvikle dine sprogudviklingskompetencer.
                        <!-- <br /><br />
                        Tryk på knappen herunder for at komme igang! -->
                    </p>
                </div>
                <!-- <v-autocomplete
                    style="margin-top: 50px; max-width: 500px"
                    rounded
                    :loading="this.loadingMunicipalities"
                    :items="this.municipalitiesList"
                    placeholder="Indtast navn på kommune"
                    outlined
                    :error-messages="this.municipalityErrorMessage"
                    plain
                    color="#25304B"
                    @change="selectMunicipality"
                    mt-50
                    label="Vælg din kommune"
                    class=""
                ></v-autocomplete> -->
                <v-btn text class="action-button" href="/municipality/1">
                    Gå til Kurser
                </v-btn>
            </v-col>
            <v-col
                key="right"
                cols="auto"
                sm="12"
                md="12"
                lg="6"
                style="padding: 20px"
            >
                <video
                    :src="this.videoUrl"
                    style="width: 100%; border-radius: 10px"
                    controls
                ></video>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getAvailableMunicipalities } from '../../plugins/api_requests';

export default {
    name: 'FirstContainer',

    data() {
        return {
            municipalitiesList: [],
            loadingMunicipalities: true,
            municipalityErrorMessage: [],
            videoUrl: `${process.env.VUE_APP_STORAGE_URL}frontpage/frontpagevideo.mp4`,
        };
    },

    beforeMount() {
        this.getMunicipalities();
    },

    methods: {
        async getMunicipalities() {
            await getAvailableMunicipalities({
                params: {
                    user_uuid: this.$cookies.get('uuid'),
                    municipality_id: this.$cookies.get('municipality'),
                },
            })
                .then((result) => {
                    this.municipalitiesList = result.data.map(
                        (municipality) => ({
                            value: municipality.id,
                            text: municipality.displayName,
                        }),
                    );
                    this.loadingMunicipalities = false;
                })
                .catch(() => {
                    this.municipalityErrorMessage =
                        'Kunne ikke hente kommuner.';
                });
        },
        selectMunicipality(value) {
            this.$router.push({
                name: 'municipality_home',
                params: { municipalityId: value },
            });
        },
    },
};
</script>
<style scoped>
.action-button {
    font-family: 'Circe-rounded', sans-serif;
    font-weight: 600;
    text-transform: none;
    background-color: var(--v-button-base) !important;
    padding: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    color: var(--v-button_text-base);
}

.heading {
    color: #2d9b85;
    font-size: 40px;
    font-weight: 500;
}
.heading-wrapper {
    display: flex;
    text-align: left;
    justify-content: left;
}
.subheading-wrapper {
    justify-content: center;
    margin-top: 15px;
    max-width: 900px;
}
.text {
    font-size: 18px;
    text-align: left;
}
.padding {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 2%;
}

.v-text-field--outlined >>> fieldset {
    border-color: #7dafca;
    border-width: 2px;
}

.box-under-video {
    color: white;
    font-family: 'Circe-rounded', sans-serif;
    padding: 25px;
    padding-left: 50px;
    margin: 0px;
    height: 200px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.primary_color {
    color: var(--v-primary-base);
}
</style>

<style>
.v-label {
    color: #29324a !important;
}
</style>
