<template>
    <v-card class="px-4 cardStyle">
        <v-card-text>
            <v-form ref="loginForm" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="loginEmail"
                            :rules="loginEmailRules"
                            label="E-mail"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="loginPassword"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            name="input-10-2"
                            :error-messages="errorMessage"
                            label="Kodeord"
                            hint="At least 8 characters"
                            value=""
                            @click:append="show1 = !show1"
                            @input="updatePassword"
                        ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex" cols="auto" sm="3" xsm="12" align-end>
                        <v-btn
                            x-large
                            block
                            :disabled="!valid"
                            color="success"
                            @click="validate"
                        >
                            Login
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { login, me } from '../../plugins/api_requests';

export default {
    beforeMount() {
        this.checkAuth();
    },

    methods: {
        async checkAuth() {
            await me()
                .then((result) => {
                    console.log(result);
                    // user is logged in redirect to municipality
                    this.$router.push({
                        name: 'municipality_home',
                        params: {
                            municipalityId: result.data.municipality_id,
                        },
                    });
                })
                .catch(() => {
                    console.log('WHAT');
                });
        },
        updatePassword() {
            this.errorMessage = '';
        },
        async validate() {
            if (this.$refs.loginForm.validate()) {
                await login(this.loginEmail, this.loginPassword)
                    .then((result) => {
                        localStorage.setItem(
                            'access_token',
                            result.data.access_token,
                        );
                        console.log(localStorage.getItem('access_token'));
                        // Set token as cookie
                        this.$cookies.set(
                            'user_name',
                            result.data.user_name,
                            '10d',
                        );
                        this.$cookies.set(
                            'municipality',
                            result.data.municipality_id,
                            '10d',
                        );

                        // redirect to municipality
                        this.$router.push({
                            name: 'municipality_home',
                            params: {
                                municipalityId: result.data.municipality_id,
                            },
                        });
                    })
                    .catch(() => {
                        this.errorMessage =
                            'Den indtastede email og password er ikke et match';
                    });
            }
        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },

    data: () => ({
        show1: false,
        errorMessage: '',

        password: 'Password',
        rules: {
            required: (value) => !!value || 'Required.',
            min: (v) => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => 'The email and password you entered dont match',
        },

        dialog: true,
        tab: 0,
        tabs: [
            { name: 'Login', icon: 'mdi-account' },
            { name: 'Register', icon: 'mdi-account-outline' },
        ],
        valid: true,
        verify: '',
        loginPassword: '',
        loginEmail: '',
        loginEmailRules: [
            (v) => !!v || 'Required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        emailRules: [
            (v) => !!v || 'Required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        // rules: {
        //     required: (value) => !!value || 'Required.',
        //     min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        // },
    }),
};
</script>
<style scoped>
.cardStyle {
    max-width: 600px;
    margin: auto;
    margin-top: 10vh;
}
</style>
