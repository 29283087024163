<template>
    <v-container class="" max-width="1080" fluid mb-10>
        <v-row>
            <v-col
                key="left"
                cols="12"
                sm="12"
                justify="center"
                text-align="center"
                class="padding"
                align-self="start"
            >
                <div class="center-content">
                    <h1 class="heading">
                        Det er nemt
                        <span class="highlight" style="">at komme i gang</span>
                    </h1>
                </div>
            </v-col>
        </v-row>
        <v-row class="padding">
            <v-col
                justify="center"
                text-align="center"
                cols="auto"
                sm="3"
                class="center-content"
            >
                <div class="number"><p>1</p></div>
                <p class="text">Hvad viser dine sprogtrappedata?</p>
            </v-col>
            <v-col
                justify="center"
                text-align="center"
                cols="auto"
                sm="3"
                class="center-content"
            >
                <div class="number"><p>2</p></div>
                <p class="text">Vælg kursus</p>
            </v-col>
            <v-col
                justify="center"
                text-align="center"
                cols="auto"
                sm="3"
                class="center-content"
            >
                <div class="number"><p>3</p></div>
                <p class="text">
                    Se videoer, og øg dine sprogudviklingskompetencer
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'SecondSection',
    data: () => ({}),
};
</script>
<style scoped lang="scss">
.number {
    background-color: #edeff4; /* Changing background color */
    color: #d77f82;
    font-family: 'Circe-rounded', sans-serif;
    font-weight: bold; /* Making font bold */
    border-radius: 100%; /* Making border radius */
    width: 70px; /* Making auto-sizable width */
    height: 70px; /* Making auto-sizable height */
    padding-top: 5px; /* Making space around letters */
    font-size: 40px; /* Changing font size */
    margin: 0 auto;

    & p {
        font-size: 40px;
    }
}
p {
    margin: 0 auto;
}
.center-content {
    margin: 0 auto;
    text-align: center;
}

.text {
    font-size: 18px;

    margin-top: 15px;
    max-width: 500px;
}
.padding {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 3%;
}

.highlight {
    color: #d77f82;
}
</style>

<style>
.v-label {
    color: #25304b !important;
}
</style>
